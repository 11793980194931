<template>
    <el-drawer :title="`“${title || '--'}”邮件发送记录详情`" :visible.sync="drawer" :append-to-body="true" direction="rtl" :before-close="() => { $emit('handleClose') }" >
        <div v-loading="loading" element-loading-text="数据较多，拼命加载中..."  class="mailSetDetail-container">
            <div v-if="drawer" class="date-container">
                <common-date @commonGetDate="commonGetDate" ref="commonReset"></common-date>
            </div>
            <div class="table-main">
                <el-table class="common-table" border :data="tableData">
                    <el-table-column prop="time" label="发送时间" :formatter="tableColumn"></el-table-column>
                    <el-table-column prop="operationName" label="操作人" :formatter="tableColumn"></el-table-column>
                    <el-table-column prop="nickName" label="发件人昵称" :formatter="tableColumn"></el-table-column>
                    <el-table-column prop="fromEmail" label="发件人邮箱" :formatter="tableColumn"></el-table-column>
                    <el-table-column prop="toEmail" label="收件人邮箱" :formatter="tableColumn"></el-table-column>
                    <el-table-column prop="ccEmail" label="抄送人邮箱" :formatter="tableColumn"></el-table-column>
                </el-table> 
            </div>
            <el-pagination
                @size-change="(e) => pageFun(e, 'pageSize')"
                @current-change="(e) => pageFun(e)"
                style="text-align: center"
                :page-sizes="pagination.pageSizes"
                :page-size="params.pageSize"
                :current-page="params.pageNum"
                layout="total, prev, pager, next,sizes, jumper"
                :total="pagination.total"
                >
            </el-pagination>
            <br/>
        </div>
    </el-drawer>
</template>
<script>
import CommonDate from "../../../components/common/commonDate.vue"
import { sendEmailRecord } from "../../../service/dataInfo.js"
import { tableColumn } from "../../../utils/index.js"
export default {
    components: { CommonDate },
    props: ['drawer'],
    data() {
        return {
            tableColumn,
            loading: false,
            title: '',
            tableData: [], // 表格数据
            params: { // 列表请求参数
                modelId:'',
                pageNum: 1,
                pageSize: 10,
                startDate: '',
                endDate: ''
            },
            pagination: { // 分页配置
                total: 0,
                pageSizes: [5, 10, 20, 50, 100],
            }
        }
    },
    methods: {
        getDetailData(modelName, modelId) {
            this.title = modelName
            this.params = { // 列表请求参数
                modelId: modelId,
                pageNum: 1,
                pageSize: 10,
                startDate: '',
                endDate: ''
            },
            this.sendEmailRecord()
        },
        async sendEmailRecord () { // 发送记录
            let params = {...this.params}
            this.loading = true
            let { data } = await sendEmailRecord(params)
            this.loading = false
            this.tableData = data.list
            this.pagination.total = data.total
        },
        commonGetDate (startTime, endTime) {
            this.params.startDate = startTime
            this.params.endDate = endTime
            this.sendEmailRecord()
        },
        pageFun(e, type) { // 分页
            if (type == "pageSize") {
                this.params.pageSize = e
            } else {
                this.params.pageNum = e
            }
            this.sendEmailRecord()
        },
    }
}
</script>
<style lang="less" scoped>
.mailSetDetail-container {
    text-align: left;
    .table-main {
        padding: 32px;
        box-sizing: border-box;
    }
    .date-container {
        height: 81px;
        display: flex;
        box-sizing: border-box;
        align-items: center;
        border-bottom: 1px solid #e6e6e6;
        padding-left: 32px;
    }
}
/deep/.el-drawer__header>:first-child {
    font-size: 18px;
}
/deep/.el-drawer__body {
    padding: 0 !important;
}
/deep/ .el-drawer__open .el-drawer.rtl {
    width: 1656px !important;
}
</style>