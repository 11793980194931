<template>
    <div v-loading="loading" element-loading-text="数据较多，拼命加载中..." class="mailSetting-container">
        <div class="title">
            日报邮件设置
            <el-button class="common-screen-btn" type="primary" @click="() => { $router.push('/mail/setting/addModel') }">新建邮件模板</el-button>
        </div>
        <div v-if="tableData && tableData.length" class="table-main">
            <el-table class="common-table" border :data="tableData">
                <el-table-column prop="modelName" label="邮件模板名称" :formatter="tableColumn">
                    <template slot-scope="scope">
                        <el-button type="text" @click="() => { detailShow(scope.row.modelName, scope.row.modelId) }">{{scope.row.modelName}}</el-button>
                    </template>
                </el-table-column>
                <el-table-column prop="customerName" label="关联客户" :formatter="tableColumn"></el-table-column>
                <el-table-column prop="nickName" label="发件人昵称" :formatter="tableColumn"></el-table-column>
                <el-table-column prop="fromEmail" label="发件人邮箱" :formatter="tableColumn"></el-table-column>
                <el-table-column prop="toEmail" label="收件人邮箱" :formatter="tableColumn"></el-table-column>
                <el-table-column prop="ccEmail" label="抄送人邮箱" :formatter="tableColumn"></el-table-column>
                <el-table-column label="操作">
                    <template slot-scope="scope">
                    <el-button type="text" size="small" @click="() => { $router.push(`/mail/setting/sendEmailOne?modelId=${scope.row.modelId}&modelName=${scope.row.modelName}`) }">使用模板</el-button>
                    <el-button type="text" size="small" @click="() => { $router.push(`/mail/setting/addModel?id=${scope.row.modelId}`) }">编辑</el-button>
                    <el-button type="text" size="small" @click="deleteIdFun(scope.row.modelId)">删除</el-button>
                    <!-- &nbsp;&nbsp;&nbsp;
                    <el-popconfirm placement="top" title="确定删除吗？" @confirm="deleteEmailMod(scope.row.modelId)">
                        <el-button type="text" size="small" slot="reference">删除</el-button>
                    </el-popconfirm> -->
                    </template>
                </el-table-column>
            </el-table>
            <!-- <el-pagination
                @size-change="(e) => pageFun(e, 'pageSize')"
                @current-change="(e) => pageFun(e)"
                style="text-align: center"
                :page-sizes="pagination.pageSizes"
                :page-size="params.pageSize"
                :current-page="params.pageNum"
                layout="total, prev, pager, next,sizes, jumper"
                :total="pagination.total"
                >
            </el-pagination> -->
        </div>
        <div v-else class="data-null">
            <img src="../../assets/images/data_null.png" alt="">
            <div class="borderBlue-btn" @click="() => { $router.push('/mail/setting/addModel') }">暂无模板，立即创建</div>
        </div>
        <mail-set-deatil ref="mailSetDeatil" :drawer="drawer" @handleClose="handleClose"></mail-set-deatil>
        <el-dialog v-dialogDrag title="删除" class="setFailDialog" :append-to-body="true" width="530px" :visible.sync="deleteVisible" :close-on-click-modal="false">
            <div>确认删除该条模板吗？</div>
            <span slot="footer" class="dialog-footer">
                <el-button class="common-screen-btn" @click="deleteVisible = false">取 消</el-button>
                <el-button class="common-screen-btn" type="primary" @click="() => {deleteEmailMod()}">确 定</el-button>
            </span>
        </el-dialog>
    </div>
</template>
<script>
import { mailModelList, deleteEmailMod } from "../../service/dataInfo.js"
import { Config, tableColumn } from "../../utils/index.js"
import MailSetDeatil from "./children/mailSetDetail.vue"
export default {
    components: { MailSetDeatil },
    data () {
        return {
            tableColumn,
            deleteId: '',
            deleteVisible: false,
            drawer: false, // 详情弹窗
            loading: false,
            companyId: this.$sto.get(Config.constants.userInfo).company_id, // 所属公司id
            tableData: [], // 表格数据
            params: { // 列表请求参数
                page: 1,
                pageSize: 10
            },
            pagination: { // 分页配置
                total: 0,
                pageSizes: [5, 10, 20, 50, 100],
            }
        }
    },
    created () {
        this.mailModelList()
    },
    methods: {
        deleteIdFun (id) { // 删除
            this.deleteId = id
            this.deleteVisible = true
        },
        detailShow (modelName, modelId) {
            this.$refs['mailSetDeatil'].getDetailData(modelName, modelId)
            this.drawer = true
        },
        async deleteEmailMod() { // 删除模板
            let { code } = await deleteEmailMod({ id: this.deleteId })
            if( code == 200 ) {
                this.$message.success('删除成功')
                this.deleteVisible = false
                this.mailModelList()
            }
        },
        async mailModelList() { // 邮件模板列表
            let params = { ...this.mailModelList }
            params.companyId = this.companyId
            this.loading = true
            let { data } = await mailModelList(params)
            this.loading = false
            this.tableData = data || []
            // this.pagination.total = data.total || 0
        },
        pageFun(e, type) { // 分页
            if (type == "pageSize") {
                this.params.pageSize = e
            } else {
                this.params.page = e
            }
            // this.serviceKPIList()
        },
        handleClose () {
            this.drawer = false
        },
    }
}
</script>
<style lang="less" scoped>
.mailSetting-container {
    text-align: left;
    padding: 16px;
    box-sizing: border-box;
    .table-main {
        padding: 16px;
        box-sizing: border-box;
    }
    .data-null {
        display: flex;
        flex-direction: column;
        align-items: center;
        padding-top: 140px;
        box-sizing: border-box;
        justify-content: space-between;
        .borderBlue-btn {
            width: 320px;
            height: 54px;
            line-height: 54px;
            border-radius: 4px;
            border: 1px solid #1890ff;
            text-align: center;
            color: #1890ff;
            background: #fff;
            cursor: pointer;
            font-size: 16px;
            margin: 44px 28px 0 0;
        }
    }
    .title {
        box-sizing: border-box;
        padding: 6px 16px 22px;
        height: 56px;
        color: #333;
        font-size: 24px;
        line-height: 24px;
        display: flex;
        justify-content: space-between;
        position: relative;
        &::after {
            display: flex;
            content: ' ';
            position: absolute;
            height: 1px;
            width: 120%;
            background: #f2f4f5;
            bottom: 0;
            left: -10%;
        }
    }
}
</style>